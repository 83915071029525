import { Box, Center } from '@chakra-ui/react'
import * as React from 'react'
import { useParams } from 'react-router-dom'

const getValidProviderSubdomain = (str: string): string => {
    if (str === 'cba') return '.cba'
    return ''
}

const CarbonWidget = () => {
    const route: { slug?: string } = useParams()
    React.useEffect(() => {
        const domain = `https://widgets${getValidProviderSubdomain(
            route.slug ?? ''
        )}.dev.acaciamoney.com`
        const script = document.createElement('script')
        script.async = true
        script.src = `${domain}/index.js?ts=` + Date.now().toString()
        document.body.appendChild(script)

        const linkElement = document.createElement('link')
        linkElement.setAttribute('rel', 'stylesheet')
        linkElement.setAttribute('type', 'text/css')
        linkElement.setAttribute('href', `${domain}/index.css`)
        document.head.appendChild(linkElement)
    }, [route])
    return (
        <Box rounded="md" p={'0'}>
            <Center borderRadius="20">
                <Box
                    id="widget-window"
                    className="acacia-widget"
                    height="100vh"
                    width="550px"
                    maxWidth="550px"
                    maxHeight="820px"
                    borderRadius="24px"
                    overflow="hidden"
                />
            </Center>
        </Box>
    )
}

export default CarbonWidget
