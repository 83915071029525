// @ts-nocheck
import React from 'react'
import {
    Box,
    ChakraProvider,
    ColorModeScript,
    Avatar,
    HStack,
    Text,
    Button,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider, InMemoryCache, ApolloClient, from, createHttpLink } from '@apollo/client'
import { createAuthLink, AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

import { useAuth0 } from './components/auth/react-auth0-wrapper'
import appSyncConfig from './config/aws-exports'

import LoginScreen from './components/auth/LoginScreen'
import Sidebar from './components/core/Sidebar'
import Routes from './components/core/Routes'
import theme from './theme'
import Spinner from 'components/common/Spinner'
import { IdToken } from '@auth0/auth0-spa-js'
// import NgoSidebar from 'components/coreNgo/Sidebar'
// import NgoRoutes from 'components/coreNgo/Routes'

function App() {
    return (
        <div className="App">
            <ChakraProvider theme={theme} resetCSS>
                <ColorModeScript initialColorMode={theme.config.initialColorMode} />
                <AppSetup />
            </ChakraProvider>
        </div>
    )
}

export type ClaimsType = {
    'http://acacia.com/orgadmin'?: string
}

type AuthContextType = {
    token?: IdToken
}

export const AuthContext = React.createContext<AuthContextType>({})

function AppSetup() {
    const { user, getIdTokenClaims, getTokenSilently, loading, isAuthenticated, token, logout } =
        useAuth0()
    React.useMemo(async () => {
        if (!loading && !isAuthenticated) {
            const t = await getTokenSilently()
            //console.log(t)
        }
    }, [loading])
    if (loading) {
        return <Spinner />
    }

    // AppSync Config with Authentication
    const url = appSyncConfig.graphqlEndpoint
    const region = appSyncConfig.region

    const auth: AuthOptions = {
        type: AUTH_TYPE.OPENID_CONNECT,
        jwtToken: async (): Promise<string> => {
            const token = (await getIdTokenClaims()) || ''
            return token.__raw
        },
    }

    // ApolloClient with AppSync Config
    const httpLink = createHttpLink({ uri: url })

    const link = from([
        createAuthLink({ url, region, auth }),
        createSubscriptionHandshakeLink(url, httpLink),
    ])

    // Required for type resolution

    const apolloClient = new ApolloClient({
        link,
        cache: new InMemoryCache(),
        connectToDevTools: true,
    })
    if (isAuthenticated) {
        // if (
        //     !(token as any)['http://acacia.com/admin'] &&
        //     !(token as any)['http://acacia.com/orgadmin'] &&
        //     !(token as any)['http://acacia.com/staff']
        // ) {
        //     // logout()
        //     return (
        //         <div>
        //             <div>NO ACCESS</div>
        //             <button onClick={() => logout()}>Logout</button>
        //         </div>
        //     )
        // }
        return (
            <AuthContext.Provider value={{ token: token }}>
                <ApolloProvider client={apolloClient}>
                    <BrowserRouter>
                        <Box
                            width="100vw"
                            height="100vh"
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            backgroundSize="cover"
                            backgroundImage="https://s3.ap-southeast-2.amazonaws.com/assets2.acaciamoney.com/mountain.jpg"
                        >

                            <HStack justifyContent="space-between" p={["1vh 2vw 1vh 2vw","1vh 2vw 1vh 2vw","5vh 5vw 1vh 5vw"]}>
                                <Link to={'/'}>
                                    <HStack>
                                        <Avatar
                                            size="sm"
                                            borderRadius="0"
                                            width="auto"
                                            src="https://s3-ap-southeast-2.amazonaws.com/assets.acaciamoney.com/logoWhite.png"
                                        />
                                        <Text fontWeight={500} color="rgba(255, 255, 255, 1)">
                                            {user.name}
                                        </Text>
                                    </HStack>
                                </Link>
                                <HStack gap="4">
                                    <Link to={'/'}>
                                    <Button variant="link" fontWeight={500} color="rgba(255, 255, 255, 1)">
                                        Home
                                    </Button>
                                    </Link>
                                    <Button variant="link" fontWeight={500} color="rgba(255, 255, 255, 1)">
                                        Profile
                                    </Button>
                                    {/* <Button variant="link" fontWeight={500} color="rgba(255, 255, 255, 1)">
                                        Contact
                                    </Button> */}
                                    <Button onClick={() => logout()} variant="link" fontWeight={500} color="rgba(255, 255, 255, 1)">
                                        Logout
                                    </Button>
                                </HStack>
                            </HStack>
                            {/* {(token as any)['http://acacia.com/orgadmin'] &&
                        (token as any)['http://acacia.com/orgadmin'].org ? (
                            <NgoSidebar>
                                <NgoRoutes />
                            </NgoSidebar>
                        ) : ( */}
                            {/* <Sidebar> */}
                            <Routes />
                            {/* </Sidebar> */}
                            {/* )} */}
                        </Box>
                    </BrowserRouter>
                </ApolloProvider>
            </AuthContext.Provider>
        )
    } else return <LoginScreen />
}

export default App
