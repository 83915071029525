import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import CarbonWidget from './carbon'
import EnergyWidget from './energy'
import SuperWidget from './super'
import SolarWidget from './solar'

const Widgets = () => {
    return (
        <Switch>
            <Route path={`/widgets/carbon`} exact component={CarbonWidget} />
            <Route path={`/widgets/energy`} exact component={EnergyWidget} />
            <Route path={`/widgets/solar`} exact component={SolarWidget} />
            <Route path={`/widgets/super`} exact component={SuperWidget} />
            <Route path={`/widgets/carbon/:slug`} component={CarbonWidget} />
            <Route path={`/widgets/energy/:slug`} component={EnergyWidget} />
        </Switch>
    )
}

export default Widgets
