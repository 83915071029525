import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Dashboard from 'components/dashboard'
// import Users from 'components/users'
// import UserDetails from 'components/users/Details'
// import Features from 'components/features'
// import Regos from 'components/regos'
// import Merchants from 'components/merchants'
// import Analysis from 'components/analysis'
// import Cdr from 'components/cdr'
// import WhiteLabel from 'components/whiteLabel'
// import Release from 'components/release'
// import Events from 'components/events'
// import PushNotifications from 'components/pushNotifications'
// import Campaigns from 'components/campaigns'
// import Switches from 'components/switches'
import Widgets from 'components/widgets'
// import UserGroups from 'components/userGroups'

const Routes = () => (
    <Switch>
        <Route path="/" exact component={Dashboard} />
        {/* <Route path="/users" exact component={Users} />
        <Route path="/userGroups" exact component={UserGroups} />
        <Route path="/users/:user_id" component={UserDetails} />
        <Route path="/features" component={Features} />
        <Route path="/regos" component={Regos} />
        <Route path="/merchants" component={Merchants} />
        <Route path="/analysis" component={Analysis} />
        <Route path="/cdr" component={Cdr} />
        <Route path="/whitelabels" component={WhiteLabel} />
        <Route path="/releases" component={Release} />
        <Route path="/events" component={Events} />
        <Route path="/push" component={PushNotifications} />
        <Route path="/campaigns" component={Campaigns} />
        <Route path="/switches" component={Switches} /> */}
        <Route path="/widgets" component={Widgets} />
    </Switch>
)

export default Routes
