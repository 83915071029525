import * as React from 'react'
import { HiBadgeCheck } from 'react-icons/hi'

import {
    Avatar,
    Box,
    Card,
    CardBody,
    CardHeader,
    Divider,
    HStack,
    Heading,
    Text,
    SimpleGrid,
    VStack,
    Flex,
    Icon,
    Center,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useAuth0 } from 'components/auth/react-auth0-wrapper'

const Dashboard = () => {
    const { user } = useAuth0()
    React.useEffect(() => {
        const localEmail = localStorage.getItem('email')
        const localFullName = localStorage.getItem('fullName')
        if (!localEmail && user.email) localStorage.setItem('email', user.email)
        if (!localFullName && user.name && !user.name.includes('@')) {
            localStorage.setItem('fullName', user.name)
        }
    }, [])
    return (
        <Box>
            <Center>
                <Flex
                    direction="column"
                    alignItems="center"
                    rounded="md"
                    padding="32px 24px"
                    mt="1vh"
                    position="relative"
                    bg="#171717"
                    shadow={{ md: 'base' }}
                    gap="4"
                >
                    <VStack gap="0">
                        <Avatar
                            size="2xl"
                            // src="https://images.unsplash.com/photo-1485178575877-1a13bf489dfe?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDV8fHdvbWFufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                            name={user.email}
                            src={user.picture}
                        />
                        {/* <Tag
                            bg="#171717"
                            top="-10px"
                            position="relative"
                            size="lg"
                            py="2"
                            variant="outline"
                            borderRadius="16"
                            colorScheme="green"
                        >
                            <TagLabel>Carbon Neutral</TagLabel>
                        </Tag> */}
                    </VStack>
                    <VStack mt="0" spacing="1" flex="1">
                        <HStack>
                            <Text color="white" fontWeight="bold" fontSize="18px">
                                {user.name}
                            </Text>
                            <Icon as={HiBadgeCheck} color="blue.300" verticalAlign="text-bottom" />
                        </HStack>
                        <Text
                            fontSize="md"
                            textAlign="center"
                            noOfLines={2}
                            color="rgba(255, 255, 255, 0.65)"
                        >
                            Product Manager
                        </Text>
                        <Text
                            fontSize="md"
                            textAlign="center"
                            noOfLines={2}
                            color="rgba(255, 255, 255, 0.65)"
                        >
                            {user.email}
                        </Text>
                    </VStack>
                    <HStack spacing="8">
                        <HStack spacing="1" fontSize="sm">
                            <Text color="rgba(255, 255, 255, 0.65)">🌲 525 trees planted</Text>
                        </HStack>
                        <HStack spacing="1" fontSize="sm">
                            <Text color="rgba(255, 255, 255, 0.65)">🌏 23.46t CO₂e offset</Text>
                        </HStack>
                    </HStack>
                </Flex>
            </Center>
            <Box rounded="md" p={['24px', '24px', '3vh 25vw 1vh 25vw', '3vh 32vw 1vh 32vw']}>
                {/* <Text
                    fontWeight={500}
                    lineHeight="1.1"
                    fontSize={["24px", "24px", "28px"]}
                    color="rgba(255, 255, 255, 0.8)"
                >
                    Potential Savings at
                </Text> */}
                <Text
                    lineHeight="1.1"
                    fontWeight={500}
                    fontSize={['24px', '24px', '28px']}
                    color="rgba(255, 255, 255, 1)"
                >
                    Your Carbon Impact
                </Text>
                <SimpleGrid templateColumns="1fr" mt="2" spacing={3}>
                    <HomeCard
                        heading="Total Carbon Impact"
                        content="39.79t CO₂e"
                        // content="Wondering how your daily actions affect the environment? We provide a detailed breakdown of your carbon emissions, so you can see the real impact of your lifestyle choices."
                        src="https://s3-ap-southeast-2.amazonaws.com/assets.acaciamoney.com/logoBlack.png"
                        route="/widgets/carbon"
                    />
                    {/* <HomeCard
                        heading={'Carbon '}
                        subheading={'CBA Branded'}
                        content="Seamlessly integrate this with your brand's identity. Your logo, color schemes, and branding elements will ensure a consistent and professional user experience"
                        src="https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/CBA.svg"
                        route="/widgets/carbon/cba"
                    /> */}
                    <HomeCard
                        heading="Energy Impact"
                        content="3.8t CO₂e"
                        // content="Simply upload your PDF energy bill, and our cutting-edge technology will extract the key data, making the process effortless and error-free.  "
                        src="https://s3-ap-southeast-2.amazonaws.com/assets.acaciamoney.com/logoBlack.png"
                        route="/widgets/energy"
                    />
                    <HomeCard
                        heading="Solar Impact"
                        content="View"
                        // content="Simply upload your PDF energy bill, and our cutting-edge technology will extract the key data, making the process effortless and error-free.  "
                        src="https://s3-ap-southeast-2.amazonaws.com/assets.acaciamoney.com/logoBlack.png"
                        route="/widgets/solar"
                    />
                    <HomeCard
                        heading="Super Impact"
                        content="26.28t CO₂e"
                        // content="Simply upload your PDF energy bill, and our cutting-edge technology will extract the key data, making the process effortless and error-free.  "
                        src="https://s3-ap-southeast-2.amazonaws.com/assets.acaciamoney.com/logoBlack.png"
                        route="/widgets/super"
                    />
                    {/* <HomeCard
                        heading="Energy Impact Branded"
                        content="3.8t CO₂e"
                        // content="Seamlessly integrate this with your brand's identity. Your logo, color schemes, and branding elements will ensure a consistent and professional user experience"
                        src="https://s3.ap-southeast-2.amazonaws.com/assets.acaciamoney.com/app/logos/banks/square/ING.svg"
                        route="/widgets/energy/ing"
                    /> */}
                </SimpleGrid>
            </Box>
        </Box>
    )
}

const Row = ({ children }) => {
    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 33.3%)', gap: '0px' }}>
            {children}
        </div>
    )
}

type Props = {
    heading?: string
    subheading?: string
    contentHeading?: string
    content?: string
    src: string
    route?: string
}

const HomeCard = ({ route, heading, subheading, content, contentHeading, src }: Props) => {
    return (
        <Link to={route}>
            <Card
                padding="12px 18px"
                cursor="pointer"
                bg="rgba(255, 255, 255, 0.7)"
                onClick={() => {}}
            >
                <CardHeader padding="4px 0 0 0">
                    <HStack gap={['4', '4', '8']}>
                        <Avatar size="xs" borderRadius="0" name={'Acacia'} src={src} width="auto" />
                        {/* <Heading fontSize="18px" color="white" fontWeight={500}>
                            {heading}
                        </Heading> */}
                        <HStack justifyContent="space-between" width="100%">
                            <Heading fontSize="18px" color="black" fontWeight={500}>
                                {heading}
                            </Heading>
                            <Heading fontSize="18px" color="black" fontWeight={500}>
                                {content}
                            </Heading>
                        </HStack>
                    </HStack>
                </CardHeader>
                {/* <Divider padding="0" borderColor="rgba(78, 78, 78, 0.25)" />
                <CardBody padding="18px 0 0 0">
                    <HStack justifyContent="space-between">
                        <Box>
                            <Heading
                                noOfLines={1}
                                fontSize="16px"
                                lineHeight="20px"
                                fontWeight={500}
                            >
                                {contentHeading}
                            </Heading>
                        </Box>
                    </HStack>
                </CardBody> */}
            </Card>
        </Link>
    )
}

const OldHomeCard = ({ route, heading, subheading, content, contentHeading, src }: Props) => {
    return (
        <Link to={route}>
            <Card padding="24px" cursor="pointer" onClick={() => {}}>
                <CardHeader padding="4px 0 24px 0">
                    <HStack gap="0" justifyContent="space-between">
                        <Heading fontSize="32px" fontWeight={500}>
                            {heading}
                        </Heading>
                        <Avatar size="md" borderRadius="0" name={'Acacia'} src={src} />
                    </HStack>
                    <Heading
                        fontSize="12px"
                        padding="6px 0 0 0"
                        textTransform="uppercase"
                        letterSpacing="1.2px"
                        color={'#41C249'}
                        fontWeight={500}
                    >
                        {subheading}
                    </Heading>
                </CardHeader>
                <Divider padding="0" borderColor="rgba(78, 78, 78, 0.25)" />
                <CardBody padding="18px 0 0 0">
                    <HStack justifyContent="space-between">
                        <Box>
                            <Heading
                                noOfLines={1}
                                fontSize="16px"
                                lineHeight="20px"
                                fontWeight={500}
                            >
                                {contentHeading}
                            </Heading>
                            <HStack padding="8px 0 0 0">
                                {/* <Avatar
                                    size="xs"
                                    borderRadius="0"
                                    name={prettyInstitutionName(plan.retailer!.name!)}
                                    src={getCustomLogo(
                                        prettyInstitutionName(plan.retailer!.name!),
                                        'square',
                                        'dark'
                                    )}
                                /> */}

                                <Text fontWeight={300}>{content}</Text>
                            </HStack>
                        </Box>
                    </HStack>
                </CardBody>
            </Card>
        </Link>
    )
}

export default Dashboard
